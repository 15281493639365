<template lang="html">

  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item nav-profile">
          <div class="nav-link">
            <div class="profile-image">
              <img src="../../assets/images/mt-generic-user.png" alt="image"/><span class="online-status online"></span>
            </div>
            <div class="profile-name">
              <p class="name">{{ username }}</p>
              <p class="designation">{{ role }}</p>
              <div class="badge badge-teal mx-auto mt-3">Online</div>
            </div>
          </div>
        </li>
        <li class="nav-item" v-if="this.mainRole == 'ROLE_ADMIN' || this.mainRole == 'ROLE_ORDERS'">
            <router-link class="nav-link" to="/paid-order-requests/"><img class="menu-icon" src="../../assets/images/menu_icons/paid-order-requests-icon.png" alt="menu icon"><span class="menu-title" v-text="$t('names.payedOrders.name')"/></router-link>
        </li>
        <li class="nav-item" v-if="this.mainRole == 'ROLE_ADMIN' || this.mainRole == 'ROLE_ORDERS'">
            <router-link class="nav-link" to="/order-requests/"><img class="menu-icon" src="../../assets/images/menu_icons/order-requests-icon.png" alt="menu icon"><span class="menu-title" v-text="$t('names.orderRequests.name')"/></router-link>
        </li>
        <li class="nav-item" v-if="this.mainRole == 'ROLE_ADMIN'">
          <span class="nav-link" v-b-toggle="'shop-items'">
            <img class="menu-icon" src="../../assets/images/menu_icons/shopping-cart-icon.png" alt="menu icon"><span class="menu-title" v-text="$t('shop')"/><i class="menu-arrow"></i>
          </span>
          <b-collapse id="shop-items">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item"><router-link class="nav-link" to="/product-categories" v-text="$t('names.productCategories.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/service-types" v-text="$t('names.services.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/products" v-text="$t('names.products.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/prices" v-text="$t('names.prices.name')"/></li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item" v-if="this.mainRole == 'ROLE_ADMIN'">
          <span class="nav-link" v-b-toggle="'cms-items'">
            <img class="menu-icon" src="../../assets/images/menu_icons/cms-icon.png" alt="menu icon"><span class="menu-title">CMS</span><i class="menu-arrow"></i>
          </span>
          <b-collapse id="cms-items">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item"><router-link class="nav-link" to="/pages/" v-text="$t('names.pages.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/fragments/" v-text="$t('names.pageFragments.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/infos/" v-text="$t('names.infos.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/departments/" v-text="$t('names.departments.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/faq-categories/" v-text="$t('names.faqCategories.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/faqs/" v-text="$t('names.faqs.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/about-items/" v-text="$t('names.about.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/carousel-items/" v-text="$t('names.carousel.name')"/></li>
              <li class="nav-item"><router-link class="nav-link" to="/documents/" v-text="$t('names.documents.name')"/></li>
            </ul>
          </b-collapse>
        </li>
      </ul>
    </nav>
  </section>

</template>

<script lang="js">
import jwtService from "../../mixins/JwtStorageService";

export default {
  name: 'app-sidebar',
  data() {
    return {
      username: '',
      role: '',
      mainRole: '',
    }
  },
  mounted() {
    this.username = jwtService.getUsername()
    if (jwtService.getRoles().includes("ROLE_ADMIN")) {
      this.role = this.$i18n.tc('roles.admin')
      this.mainRole = "ROLE_ADMIN"
    } else if (jwtService.getRoles().includes("ROLE_ORDERS")) {
      this.role = this.$i18n.tc('roles.orders')
      this.mainRole = "ROLE_ORDERS"
    }
  }
}
</script>

<style scoped lang="scss">
.app-sidebar {

}
</style>
