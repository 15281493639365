var jwtService = {
    getAccessToken() {
        return localStorage.getItem('access_token')
    },
    setAccessToken(token) {
        localStorage.setItem('access_token', token);
    },
    getRefreshToken() {
        return localStorage.getItem('refresh_token')
    },
    setRefreshToken(token) {
        localStorage.setItem('refresh_token', token);
    },
    getUsername() {
        return localStorage.getItem('username')
    },
    setUsername(username) {
        localStorage.setItem('username', username);
    },
    getRoles() {
        return JSON.parse(localStorage.getItem('roles'));
    },
    setRoles(roles) {
        localStorage.setItem('roles', JSON.stringify(roles));
    },
    setLoginData(accessToken, refreshToken, username, roles) {
        this.setAccessToken(accessToken)
        this.setRefreshToken(refreshToken)
        this.setUsername(username)
        this.setRoles(roles)
    },
    removeLoginData() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('username');
        localStorage.removeItem('roles');
    }
}

export default jwtService