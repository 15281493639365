<template>
    <section class="product-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">{{ $t('createNewTitle') }}</h4>
                        <h4 v-else class="card-title">{{ $t('editEntry') }} <strong>{{itemName}}</strong> <code>(id {{item.id}})</code></h4>
                        <p class="card-description pb-4">{{ $t('fillFieldsBelow') }}</p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-4">
                                    <b-form-group :label="$t('forms.name')" label-for="name-input">
                                        <b-form-input type="text" id="name-input" :placeholder="$t('forms.name')" v-model="item.name" :state="nameState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group :label="$t('forms.category')" label-for="category-input">
                                        <b-form-select v-model="item.category.id" :options="categories" value-field="id" text-field="name" :state="categoryState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group :label="$t('forms.order')" label-for="order-input">
                                        <b-form-input type="number" id="order-input" :placeholder="$t('forms.order')" v-model="item.itemOrder" :state="orderState" min="1"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2 my-auto">
                                    <b-form-group :label="$t('forms.homePage')" label-for="category-input">
                                        <b-form-checkbox v-model="item.onFrontPage" name="check-button" switch size="lg">
                                            <p v-text="item.onFrontPage ? $t('forms.yes') : $t('forms.no')" style="margin-top: 4px;" class="pl-2" />
                                        </b-form-checkbox>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group label="URL" label-for="url-input">
                              <b-form-input type="text" id="url-input" placeholder="URL" v-model="item.url" :state="urlState"/>
                            </b-form-group>

                            <b-form-group :label="$t('forms.question')" label-for="question-input" class="mb-4">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="question-input" :placeholder="$t('forms.question') + ' (' + lang.toUpperCase() + ')'" v-model="item.question" :state="questionState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="questionEn-input" :placeholder="$t('forms.question') + ' (EN)'" v-model="item.questionEn" :state="questionEnState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group :label="$t('forms.answer') + ' (' + lang.toUpperCase() + ')'" label-for="answer-input">
                                <b-form-textarea rows="10" id="answer-input" :placeholder="$t('forms.answer')" v-model="item.answer" :state="answerState"/>
                            </b-form-group>

                            <b-form-group :label="$t('forms.answer') + ' (EN)'" label-for="answerEn-input">
                                <b-form-textarea rows="10" id="answerEn-input" :placeholder="$t('forms.answer')" v-model="item.answerEn" :state="answerEnState"/>
                            </b-form-group>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">{{ $t('forms.save') }}</b-button>
                            <b-button variant="light" v-on:click="cancel">{{ $t('forms.cancel') }}</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>{{ $t('forms.delete') }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light" :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.delete')" @ok="deleteItem">
            <p class="text-center">{{ $t('forms.deleteConfirmationMessage') }}</p>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'FaqItem',
        computed: {
            nameState() {
                if (this.item.name) {
                    return this.item.name.length > 0
                } else {
                    return false
                }
            },
            questionState() {
                if (this.item.question) {
                    return this.item.question.length > 0
                } else {
                    return false
                }
            },
            questionEnState() {
                if (this.item.questionEn) {
                    return this.item.questionEn.length > 0
                } else {
                    return false
                }
            },
            answerState() {
                if (this.item.answer) {
                    return this.item.answer.length > 0
                } else {
                    return false
                }
            },
            answerEnState() {
                if (this.item.answerEn) {
                    return this.item.answerEn.length > 0
                } else {
                    return false
                }
            },
            orderState() {
                if (this.item.itemOrder) {
                    return this.item.itemOrder > 0
                } else {
                    return false
                }
            },
            categoryState() {
                if (this.item.category) {
                    return this.item.category.id > 0
                } else {
                    return false
                }
            },
            urlState() {
                if (this.item.url) {
                    return this.item.url.length > 0 &&
                        this.item.url.startsWith('/') &&
                        this.item.url.split('/').length === 2
                } else {
                    return false
                }
            },
            validated() {
                return !this.nameState || !this.questionState || !this.questionEnState || !this.answerState ||
                    !this.answerEnState ||  !this.categoryState || !this.orderState ||!this.urlState
            }
        },
        data() {
            return {
                lang: Const.LANG,
                categories: [],
                item: {},
                itemName: ''
            }
        },
        mounted() {
            axios.get(Const.API_HOST + '/admin/faqCategories')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.categories = body
            })
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/faqItem/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.itemName = JSON.parse(JSON.stringify(this.item.name))
                })
            } else {
                this.item = {
                    name: '',
                    itemOrder: '',
                    category: {},
                    question: '',
                    questionEn: '',
                    answer: '',
                    answerEn: '',
                    onFrontPage: false,
                    url: ''
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/faqs')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/faqItem/save', {
                    faqItem: {
                        id: this.item.id,
                        name: this.item.name,
                        itemOrder: this.item.itemOrder,
                        category: this.item.category,
                        question: this.item.question,
                        questionEn: this.item.questionEn,
                        answer: this.item.answer,
                        answerEn: this.item.answerEn,
                        onFrontPage: this.item.onFrontPage,
                        url: this.item.url
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/faqs')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/faqItem/delete', {
                    data: {
                        id: this.item.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/faqs')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }
</style>
