<template>
    <section class="tables">
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title">{{ $t('names.about.name') }}</h2>
                        <p class="card-description">{{ $t('names.about.description') }}</p>
                        <b-button variant="primary" class="mt-4 mb-4" @click="$router.push('/about-item/new')">{{ $t('createNewTitle') }}</b-button>
                        <b-table striped hover :items="aboutItems" :fields="fields" @row-clicked="editAboutItem"
                                 class="clickable"></b-table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import {Const} from '../../../main'

export default {
    name: 'AboutItemList',
    data() {
        return {
            aboutItems: [],
            fields: [
                {
                    key: 'id',
                    label: 'ID',
                    sortable: true,
                    thStyle: 'width: 75px;'
                },
                {
                    key: 'name',
                    label: this.$t('forms.name'),
                    sortable: true
                }
            ]
        }
    },
    mounted() {
        axios.get(Const.API_HOST + '/admin/aboutItems')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
            // eslint-disable-next-line no-console
            console.log(error)
        }).then(body => {
            this.aboutItems = body
        })
    },
    methods: {
        editAboutItem(item) {
            // eslint-disable-next-line no-console
            console.log(item)
            this.$router.push('/about-item/edit/' + item.id)
        }
    }
}
</script>

<style scoped>
.clickable /deep/ tbody td {
    cursor: pointer;
}

</style>
