<template>
    <section class="carousel-sec-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">{{ $t('createNewTitle') }}</h4>
                        <h4 v-else class="card-title">{{ $t('editEntry') }} <strong>{{ carouselItemName }}</strong> <code>(id {{ carouselItem.id }})</code></h4>
                        <p class="card-description pb-4">{{ $t('fillFieldsBelow') }}</p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group :label="$t('forms.name')" label-for="name-input">
                                        <b-form-input type="text" id="name-input" :placeholder="$t('forms.name')" v-model="carouselItem.name" :state="nameState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-form-group label="URL" label-for="summary-input">
                                        <b-form-input type="text" id="summary-input" placeholder="URL" v-model="carouselItem.url" :state="urlState"/>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group :label="$t('forms.title')" label-for="title-input" class="mb-4">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="title-input" :placeholder="$t('forms.title') + ' (' + lang.toUpperCase() + ')'" v-model="carouselItem.title" :state="titleState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="titleEn-input" :placeholder="$t('forms.title') + ' (EN)'" v-model="carouselItem.titleEn" :state="titleEnState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group :label="$t('forms.description')" label-for="description-input" class="mb-4">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="description-input" :placeholder="$t('forms.description') + ' (' + lang.toUpperCase() + ')'" v-model="carouselItem.summary" :state="summaryState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="descriptionEn-input" :placeholder="$t('forms.description') + ' (EN)'" v-model="carouselItem.summaryEn" :state="summaryEnState"/></div>
                                </div>
                            </b-form-group>

                            <div class="row">
                                <div class="col-lg-2">
                                    <b-form-group :label="$t('forms.order')" label-for="order-input">
                                        <b-form-input type="number" id="order-input" :placeholder="$t('forms.order')" v-model="carouselItem.itemOrder" :state="orderState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-3">
                                    <b-form-group :label="$t('forms.mainImage')" label-for="image-input">
                                        <b-button id="image-input"
                                                  :variant="carouselItem.image && carouselItem.image.id ? 'outline-success' : 'outline-danger'"
                                                  @click="openImagePicker('image-input')">
                                            {{ carouselItem.image && carouselItem.image.id ? carouselItem.image.filename.substr(8) : $t('forms.select') }}
                                        </b-button>
                                        <b-popover v-if="carouselItem.image && carouselItem.image.id"
                                                   target="image-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="carouselItem.image.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">{{ $t('forms.imageSize') }}: {{ carouselItem.image.width }} x {{ carouselItem.image.height }}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-3">
                                    <b-form-group :label="$t('forms.thumbnail')" label-for="thumbnail-input">
                                        <b-button id="thumbnail-input"
                                                  :variant="carouselItem.thumbnail && carouselItem.thumbnail.id ? 'outline-success' : 'outline-danger'"
                                                  @click="openImagePicker('thumbnail-input')" :state="thumbnailState">
                                            {{ carouselItem.thumbnail && carouselItem.thumbnail.id ? carouselItem.thumbnail.filename.substr(8) : $t('forms.select') }}
                                        </b-button>
                                        <b-popover v-if="carouselItem.thumbnail && carouselItem.thumbnail.id" target="thumbnail-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="carouselItem.thumbnail.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">{{ $t('forms.imageSize') }}: {{ carouselItem.thumbnail.width }} x {{ carouselItem.thumbnail.height }}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">{{ $t('forms.save') }}</b-button>
                            <b-button variant="light" v-on:click="cancel">{{ $t('forms.cancel') }}</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>{{ $t('forms.delete') }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light"
                 :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.delete')" @ok="deleteItem">
            <p class="text-center">{{ $t('forms.deleteConfirmationMessage') }}</p>
        </b-modal>

        <b-modal id="image-picker" :title="$t('forms.imagePickerTitle')" size="xl" header-bg-variant="primary" header-text-variant="light"
                 :okTitle="$t('forms.close')" ok-only>
            <div class="row images">
                <div class="col-lg-1 my-auto text-center p-1" v-for="image in images" :key="image.id"
                     :id="'image-wrapper-' + image.id">
                    <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image"
                         @click="pasteImage(image.id)">
                    <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                        <div class="row">
                            <div class="col text-center">
                                <span><em>{{ image.filename.substr(8) }}</em></span><br>
                                <span class="p-0">{{ image.width + ' x ' + image.height }}</span>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>
        </b-modal>
    </section>
</template>

<script>
import axios from 'axios'
import {Const} from '../../../main'

export default {
    name: 'CarouselItem',
    computed: {
        nameState() {
            if (this.carouselItem.name) {
                return this.carouselItem.name.length > 0
            } else {
                return false
            }
        },
        titleState() {
            if (this.carouselItem.title) {
                return this.carouselItem.title.length > 0
            } else {
                return false
            }
        },
        titleEnState() {
            if (this.carouselItem.titleEn) {
                return this.carouselItem.titleEn.length > 0
            } else {
                return false
            }
        },
        summaryState() {
            if (this.carouselItem.summary) {
                return this.carouselItem.summary.length > 0
            } else {
                return false
            }
        },
        summaryEnState() {
            if (this.carouselItem.summaryEn) {
                return this.carouselItem.summaryEn.length > 0
            } else {
                return false
            }
        },
        urlState() {
            if (this.carouselItem.url) {
                return this.carouselItem.url.length > 0
            } else {
                return false
            }
        },
        orderState() {
            if (this.carouselItem.itemOrder) {
                return this.carouselItem.itemOrder > 0
            } else {
                return false
            }
        },
        imageState() {
            return this.carouselItem.image && this.carouselItem.image.id > 0
        },
        thumbnailState() {
            return this.carouselItem.thumbnail && this.carouselItem.thumbnail.id > 0
        },
        validated() {
            return !this.nameState || !this.titleState || !this.titleEnState || !this.summaryState ||
                !this.summaryEnState || !this.orderState || !this.urlState || !this.imageState || !this.thumbnailState
        }
    },
    data() {
        return {
            lang: Const.LANG,
            images: [],
            carouselItem: {},
            carouselItemName: '',
            imagePickerContext: ''
        }
    },
    mounted() {
        if (this.$attrs.mode === Const.MODE_EDIT) {
            axios.get(Const.API_HOST + '/admin/carouselItem/get', {
                params: {
                    'id': this.$route.params.id
                }
            }).then(function (response) {
                return response.data
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.carouselItem = body
                this.carouselItemName = JSON.parse(JSON.stringify(this.carouselItem.name))
            })
        } else {
            this.carouselItem = {
                id: '',
                name: '',
                title: '',
                titleEn: '',
                summary: '',
                summaryEn: '',
                url: '',
                image: {},
                thumbnail: {},
                itemOrder: '',
            }
        }
    },
    methods: {
        cancel() {
            this.$router.push('/carousel-items')
        },
        saveItem() {
            var app = this
            axios.post(Const.API_HOST + '/admin/carouselItem/save', {
                carouselItem: {
                    id: this.carouselItem.id,
                    name: this.carouselItem.name,
                    title: this.carouselItem.title,
                    titleEn: this.carouselItem.titleEn,
                    summary: this.carouselItem.summary,
                    summaryEn: this.carouselItem.summaryEn,
                    url: this.carouselItem.url,
                    thumbnail: this.carouselItem.thumbnail,
                    image: this.carouselItem.image,
                    itemOrder: this.carouselItem.itemOrder,
                }
            }).then(function (response) {
                // eslint-disable-next-line no-console
                console.log(response)
                if (response.status === 200) {
                    app.$router.push('/carousel-items')
                }
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            })
        },
        deleteItem() {
            var app = this
            axios.delete(Const.API_HOST + '/admin/carouselItem/delete', {
                data: {
                    id: this.carouselItem.id
                }
            }).then(function (response) {
                // eslint-disable-next-line no-console
                console.log(response)
                if (response.status === 200) {
                    app.$router.push('/carousel-items')
                }
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            })
        },
        openImagePicker(context) {
            this.imagePickerContext = context
            this.$bvModal.show('image-picker')
            axios.get(Const.API_HOST + '/admin/images')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.images = body
            })
        },
        pasteImage(id) {
            var selected = this.images.find(obj => {
                return obj.id === id
            })

            // eslint-disable-next-line no-console
            console.log(selected.filename)

            if (this.imagePickerContext === 'image-input') {
                this.carouselItem.image = JSON.parse(JSON.stringify(selected))
            } else if (this.imagePickerContext === 'thumbnail-input') {
                this.carouselItem.thumbnail = JSON.parse(JSON.stringify(selected))
            }

            this.$bvModal.hide('image-picker')
        }
    }
}
</script>

<style scoped>
.clickable /deep/ tbody td {
    cursor: pointer;
}

.browser-image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}

/deep/ .tox-editor-container div:first-child {
    top: 58px !important;
}
</style>
