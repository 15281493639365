<template>
    <section class="tables">
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h2 class="card-title">{{ $t('names.prices.name') }}</h2>
                        <p class="card-description">{{ $t('names.prices.description') }}</p>
                        <b-button variant="primary" class="mt-4 mb-4" @click="$router.push('/price/new')">{{ $t('createNewTitle') }}</b-button>
                        <b-table striped hover :items="items" :fields="fields" @row-clicked="editItem" class="clickable"></b-table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'ProductItemVariationList',
        data() {
            return {
                items: [],
                fields: [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true,
                        thStyle: 'width: 75px;'
                    },
                    {
                        key: 'productItem.name',
                        label: this.$t('forms.productName'),
                        sortable: true
                    },
                    {
                        key: 'serviceType.name',
                        label: this.$t('forms.service'),
                        sortable: true
                    },
                    {
                        key: 'netPrice',
                        label: this.$t('forms.netPrice'),
                        sortable: true
                    }
                ]
            }
        },
        mounted() {
            axios.get(Const.API_HOST + '/admin/variations')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.items = body
                this.items.forEach(function (as) {
                    if (as.netPrice === undefined || as.netPrice === null) {
                        as.netPrice = 0
                    }
                    as.netPrice = as.netPrice.toFixed(2)
                })
            })
        },
        methods: {
            editItem(item) {
                // eslint-disable-next-line no-console
                console.log(item)
                this.$router.push('/price/edit/' + item.id)
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

</style>
