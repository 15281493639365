<template>
    <section class="product-variation">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">{{ $t('createNewTitle') }}</h4>
                        <h4 v-else class="card-title">{{ $t('editEntry') }} <strong>{{productName}}, {{servieName}}</strong> <code>(id {{item.id}})</code></h4>
                        <p class="card-description pb-4">{{ $t('fillFieldsBelow') }}</p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-5">
                                    <b-form-group :label="$t('forms.productName')" label-for="product-input">
                                        <b-form-select id="product-input" :disabled="$attrs.mode === 'edit'" v-model="item.productItem.id" :options="$attrs.mode === 'edit' ? products : getAvailableProducts()" @change="prepareAvailableServices()" value-field="id" text-field="name" :state="productState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-5">
                                    <b-form-group :label="$t('forms.service')" label-for="service-input">
                                        <b-form-select id="service-input" :disabled="$attrs.mode === 'edit' || !item.productItem.id" v-model="item.serviceType.id" :options="$attrs.mode === 'edit' ? services : availableServices" value-field="id" text-field="name" :state="serviceState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group :label="$t('forms.netPrice')" label-for="price-input">
                                        <b-form-input type="number" id="price-input" :placeholder="$t('forms.netPrice')" v-model="item.netPrice" :state="netPriceState" min="1"/>
                                    </b-form-group>
                                </div>
                            </div>
                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">{{ $t('forms.save') }}</b-button>
                            <b-button variant="light" v-on:click="cancel">{{ $t('forms.cancel') }}</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>{{ $t('forms.delete') }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light" :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.delete')" @ok="deleteItem">
            <p class="text-center">{{ $t('forms.deleteConfirmationMessage') }}</p>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'ProductItemVariation',
        computed: {
            productState() {
                if (this.item.productItem) {
                    return this.item.productItem.id > 0
                } else {
                    return false
                }
            },
            serviceState() {
                if (this.item.serviceType) {
                    return this.item.serviceType.id > 0 && this.globalConfigurationState
                } else {
                    return false
                }
            },
            netPriceState() {
                if (this.item.netPrice) {
                    return this.item.netPrice > 0
                } else {
                    return false
                }
            },
            globalConfigurationState() {
                if (this.$attrs.mode === Const.MODE_NEW && this.item.productItem.id && this.item.serviceType.id) {
                    var selected = this.configured.find(obj => {
                        return obj.productItem.id === this.item.productItem.id &&
                                obj.serviceType.id === this.item.serviceType.id
                    })
                    return !selected;
                }

                return true
            },
            validated() {
                return !this.productState || !this.serviceState || !this.netPriceState ||
                    !this.globalConfigurationState
            }
        },
        data() {
            return {
                item: {},
                products: [],
                services: [],
                configured: [],
                available: [],
                availableServices: [],
                productName: '',
                servieName: ''
            }
        },
        mounted() {
            axios.all([
                axios.get(Const.API_HOST + '/admin/productItems'),
                axios.get(Const.API_HOST + '/admin/serviceTypes'),
                axios.get(Const.API_HOST + '/admin/variations')
            ])
            .then(responseArr => {
                this.products = responseArr[0].data
                this.services = responseArr[1].data
                this.configured = responseArr[2].data
                this.prepareCombinations()
            });

            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/variation/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.productName = JSON.parse(JSON.stringify(this.item.productItem.name))
                    this.servieName = JSON.parse(JSON.stringify(this.item.serviceType.name))
                    if (this.item.netPrice === undefined || this.item.netPrice === null) {
                        this.item.netPrice = 0
                    }
                    this.item.netPrice = this.item.netPrice.toFixed(2)
                })
            } else {
                this.item = {
                    productItem: {},
                    serviceType: {},
                    netPrice: 0,
                }
            }
        },
        methods: {
            prepareCombinations() {
                if (this.$attrs.mode === Const.MODE_NEW) {
                    for (var i = 0; i < this.products.length; i++) {
                        for (var j = 0; j < this.services.length; j++) {
                            this.available.push({
                                product: this.products[i].id,
                                service: this.services[j].id
                            });
                        }
                    }
                    this.available = this.available.filter(obj => {
                        let combination = this.configured.find(comb => {
                            return (comb.productItem.id === obj.product && comb.serviceType.id === obj.service)
                        })
                        return !combination
                    })
                }
            },
            getAvailableProducts() {
                if (this.$attrs.mode === Const.MODE_NEW) {
                    return this.products.filter(obj => {
                        let product = this.available.find(prod => {
                            return prod.product === obj.id
                        })
                        return product
                    })
                }

                return this.products
            },
            prepareAvailableServices() {
                if (this.$attrs.mode === Const.MODE_NEW) {
                    this.availableServices = this.services.filter(obj => {
                        return this.available.find(prod => {
                            return prod.product === this.item.productItem.id && prod.service === obj.id
                        })
                    })
                }
            },
            cancel() {
                this.$router.push('/prices')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/variation/save', {
                    id: this.item.id,
                    productId: this.item.productItem.id,
                    serviceId: this.item.serviceType.id,
                    price: this.item.netPrice,
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/prices')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/variation/delete', {
                    data: {
                        id: this.item.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/prices')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

</style>
