<template>
    <section class="service-type">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">{{ $t('createNewTitle') }}</h4>
                        <h4 v-else class="card-title">{{ $t('editEntry') }} <strong>{{itemName}}</strong> <code>(id {{item.id}})</code></h4>
                        <p class="card-description pb-4">{{ $t('fillFieldsBelow') }}</p>
                        <div class="forms-sample">
                            <b-form-group :label="$t('forms.name')" label-for="title-input">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="title-input" :placeholder="$t('forms.name') + ' (' + lang.toUpperCase() + ')'" v-model="item.name" :state="nameState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="titleEn-input" :placeholder="$t('forms.name') + ' (EN)'" v-model="item.nameEn" :state="nameEnState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group :label="$t('forms.order')" label-for="order-input">
                                <b-form-input type="number" id="order-input" :placeholder="$t('forms.order')" v-model="item.itemOrder" :state="orderState" min="1"/>
                            </b-form-group>

                            <b-button v-if="$attrs.mode === 'new'" variant="success" class="mr-2" @click="saveItem" :disabled="validated">{{ $t('forms.save') }}</b-button>
                            <b-button v-else type="submit" variant="success" class="mr-2" v-b-modal.confirm-save :disabled="validated">{{ $t('forms.save') }}</b-button>
                            <b-button variant="light" v-on:click="cancel">{{ $t('forms.cancel') }}</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>{{ $t('forms.delete') }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-save" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light" :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.save')" @ok="saveItem">
            <p class="text-center">{{ $t('forms.deleteConfirmationMessageExt') }}</p>
        </b-modal>
        <b-modal id="confirm-delete" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light" :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.delete')" @ok="deleteItem">
            <p class="text-center">{{ $t('forms.saveConfirmationMessageExt') }}</p>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'ServiceType',
        computed: {
            nameState() {
                if (this.item.name) {
                    return this.item.name.length > 0
                } else {
                    return false
                }
            },
            nameEnState() {
                if (this.item.nameEn) {
                    return this.item.nameEn.length > 0
                } else {
                    return false
                }
            },
            orderState() {
                if (this.item.itemOrder) {
                    return this.item.itemOrder > 0
                } else {
                    return false
                }
            },
            validated() {
                return !this.nameState || !this.nameEnState || !this.orderState
            }
        },
        data() {
            return {
                lang: Const.LANG,
                item: {},
                itemName: ''
            }
        },
        mounted() {
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/serviceType/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.itemName = JSON.parse(JSON.stringify(this.item.name))
                })
            } else {
                this.item = {
                    name: '',
                    nameEn: '',
                    itemOrder: ''
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/service-types')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/serviceType/save', {
                    id: this.item.id,
                    name: this.item.name,
                    nameEn: this.item.nameEn,
                    itemOrder: this.item.itemOrder
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/service-types')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/serviceType/delete', {
                    data: {
                        id: this.item.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/service-types')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

</style>
