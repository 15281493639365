<template>
    <section class="images">
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body" :class="{'drop-active': $refs.upload && $refs.upload.dropActive}">
                        <div class="row">
                            <div class="col">
                                <div id="upload">
                                    <div class="upload">
                                        <div v-if="files.length">
                                            <table role="table" aria-busy="false" aria-colcount="3" class="table b-table table-striped table-hover">

                                                <thead role="rowgroup" class="">
                                                    <tr role="row" class="">
                                                        <th role="columnheader" scope="col" aria-colindex="1" class="">{{ $t('forms.name') }}</th>
                                                        <th role="columnheader" scope="col" aria-colindex="2" class="" style="width: 100px;">{{ $t('forms.imageSize') }}</th>
                                                        <th role="columnheader" scope="col" aria-colindex="3" class="text-center" style="width: 150px;">{{ $t('forms.status') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody role="rowgroup">
                                                    <tr v-for="file in files" :key="file.id">
                                                        <td aria-colindex="1" role="cell" class="" v-text="file.name"/>
                                                        <td aria-colindex="2" role="cell" class="" v-text="humanFileSize(file.size)"/>
                                                        <td aria-colindex="3" role="cell" class="text-center">
                                                            <b-button v-if="file.error" squared variant="danger" disabled>{{data.error}}</b-button>
                                                            <b-button v-else-if="file.success" squared variant="success" disabled>{{ $t('forms.statusOk') }}</b-button>
                                                            <b-button v-else-if="file.active" squared variant="warning" disabled>{{ $t('forms.statusSending') }}</b-button>
                                                            <b-button v-else squared disabled>{{ $t('forms.statusWaiting') }}</b-button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-else class="text-center p-5">
                                            <h4>{{ $t('forms.dropHere') }} <br/>{{ $t('forms.or') }}</h4>
                                            <label for="file" class="btn btn-lg btn-primary">{{ $t('forms.selectFromDisk') }}</label>
                                        </div>

                                        <div class="upload-btn text-right pt-3">
                                            <file-upload class="btn btn-primary p-3 ml-2 mr-2" :post-action="uploadUrl" :multiple="true" :headers="authHeader"
                                                         @input-file="inputFile" @input-filter="inputFilter"
                                                         :drop="true" v-model="files" ref="upload">
                                                <i class="fa fa-plus"></i> {{ $t('forms.selectFromDisk') }}
                                            </file-upload>
                                            <button type="button" class="btn btn-success p-3 ml-2 mr-2" v-if="!$refs.upload || !$refs.upload.active"
                                                    @click.prevent="$refs.upload.active = true">
                                                {{ $t('forms.startUpload') }}
                                            </button>
                                            <button type="button" class="btn btn-danger p-3 ml-2 mr-2" v-else @click.prevent="$refs.upload.active = false">
                                                {{ $t('forms.stopUpload') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4>{{ $t('forms.files') }}</h4>
                        <b-table striped hover :items="items" :fields="fields">
                            <template #cell(copyUrl)="row">
                                <b-button size="sm" class="mr-2" @click="showUrlAction(row.item)">
                                    {{ $t('forms.showUrl') }}
                                </b-button>
                            </template>
                            <template #cell(delete)="row">
                                <b-button size="sm" class="mr-2" variant="danger" @click="addFileToDelete(row.item)" v-b-modal.confirm-delete>
                                    {{ $t('forms.delete') }}
                                </b-button>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="show-url-modal" ref="modal" :title="formatFilename" size="lg" ok-only>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <b-form-group label="URL" label-for="name-input">
                            <b-form-input v-model="formatUrl" type="text" id="name-input" disabled/>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal id="confirm-delete" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light" :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.delete')" @ok="deleteFile">
            {{ $t('forms.deleteConfirmationMessage') }}
        </b-modal>
    </section>
</template>

<script>
    import axios from "axios";
    import {Const} from "../../main";
    import jwtService from "../../mixins/JwtStorageService";
    import moment from "moment/moment";

    export default {
        name: "FileList",
        computed: {
            formatUrl() {
                if (this.editedItem.filename)
                    return Const.WEB_HOST + this.editedItem.filename

                return ''
            },
            formatFilename() {
                if (this.editedItem.filename)
                    return this.trimEllip(this.editedItem.filename.substr(7), 120)

                return ''
            }
        },
        data() {
            return {
                items: [],
                editedItem: {},
                files: [],
                uploadUrl: Const.API_HOST + '/admin/file/upload',
                fields: [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true,
                    },
                    {
                        key: 'filename',
                        label: this.$t('forms.name'),
                        sortable: true,
                        formatter: value => {
                            return this.trimEllip(value.substr(7), 120)
                        }
                    },
                    {
                        key: 'uploadDate',
                        label: this.$t('forms.created'),
                        sortable: true,
                        formatter: value => {
                            return moment(value).format('DD.MM.YYYY, HH:mm');
                        }
                    },
                    {
                        key: 'copyUrl',
                        label: '',
                        thStyle: 'width: 135px;'
                    },
                    {
                        key: 'delete',
                        label: '',
                        thStyle: 'width: 100px;'
                    }
                ],
                authHeader: {}
            }
        },
        mounted() {
            axios.get(Const.API_HOST + '/admin/files')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.items = body
            })

            const token = jwtService.getAccessToken();
            if (token) {
                this.authHeader = {'Authorization': 'Bearer ' + token}
            }
        },
        methods: {
            trimEllip(s, length) {
                return s.length > length ? s.substring(0, length) + "..." : s;
            },
            showUrlAction(item) {
                this.editedItem = item
                this.$bvModal.show('show-url-modal')
            },
            addFileToDelete(item) {
                this.editedItem = item
            },
            deleteFile() {
                axios.post(Const.API_HOST + '/admin/file/remove', {
                    id: this.editedItem.id
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    window.location.reload()
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
                this.editedItem = {}
            },
            inputFile: function (newFile, oldFile) {
                if (newFile && oldFile && !newFile.active && oldFile.active) {
                    // Get response data
                    // eslint-disable-next-line no-console
                    console.log('response', newFile.response)
                    if (newFile.xhr) {
                        //  Get the response status code
                        // eslint-disable-next-line no-console
                        console.log('status', newFile.xhr.status)
                    }
                }
            },
            inputFilter: function (newFile, oldFile) {
                if (newFile && !oldFile) {
                    // Filter non-image file
                    // not used for files
                }

                // Create a blob field
                newFile.blob = ''
                let URL = window.URL || window.webkitURL
                if (URL && URL.createObjectURL) {
                    newFile.blob = URL.createObjectURL(newFile.file)
                }
            },
            humanFileSize (size) {
                var i = Math.floor( Math.log(size) / Math.log(1024) );
                return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
            }
        }
    }
</script>

<style scoped>
    .browser-image {
        max-width: 100%;
        max-height: 100%;
    }

    .browser-image-icon {
        position: absolute;
        font-size: 16pt;
        color: #dc1212;
        top: 17pt;
        right: 15pt;
        cursor: pointer;
    }

    .drop-active::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: blue;
        opacity: 0.05;
    }
</style>