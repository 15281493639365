<template>
    <section class="product-category">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">{{ $t('createNewTitle') }}</h4>
                        <h4 v-else class="card-title">{{ $t('editEntry') }} <strong>{{itemName}}</strong> <code>(id {{item.id}})</code></h4>
                        <p class="card-description pb-4">{{ $t('fillFieldsBelow') }}</p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-3">
                                    <b-form-group :label="$t('forms.name')" label-for="name-input">
                                        <b-form-input type="text" id="name-input" :placeholder="$t('forms.name')" v-model="item.name" :state="nameState" disabled/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                  <b-form-group :label="$t('forms.order')" label-for="order-input">
                                    <b-form-input type="number" id="order-input" :placeholder="$t('forms.order')" v-model="item.itemOrder" :state="orderState" min="1"/>
                                  </b-form-group>
                                </div>
                            </div>
                            <b-form-group :label="$t('forms.title')" label-for="title-input">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="title-input" :placeholder="$t('forms.title') + ' (' + lang.toUpperCase() + ')'" v-model="item.title" :state="titleState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="titleEn-input" :placeholder="$t('forms.title') + ' (EN)'" v-model="item.titleEn" :state="titleEnState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group :label="$t('forms.description')" label-for="description-input">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="title-input" :placeholder="$t('forms.description') + ' (' + lang.toUpperCase() + ')'" v-model="item.description" :state="descriptionState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="titleEn-input" :placeholder="$t('forms.description') + ' (EN)'" v-model="item.descriptionEn" :state="descriptionEnState"/></div>
                                </div>
                            </b-form-group>

                            <b-button v-if="$attrs.mode === 'new'" variant="success" class="mr-2" @click="saveItem" :disabled="validated">{{ $t('forms.save') }}</b-button>
                            <b-button v-else type="submit" variant="success" class="mr-2" v-b-modal.confirm-save :disabled="validated">{{ $t('forms.save') }}</b-button>
                            <b-button variant="light" v-on:click="cancel">{{ $t('forms.cancel') }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-save" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light" :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.save')" @ok="saveItem">
            <p class="text-center">{{ $t('forms.saveConfirmationMessageExt') }}</p>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'

    export default {
        name: 'ProductCategory',
        computed: {
            nameState() {
                if (this.item.name) {
                    return this.item.name.length > 0
                } else {
                    return false
                }
            },
            orderState() {
                if (this.item.itemOrder) {
                    return this.item.itemOrder > 0
                } else {
                    return false
                }
            },
            titleState() {
                if (this.item.title) {
                    return this.item.title.length > 0
                } else {
                    return false
                }
            },
            titleEnState() {
                if (this.item.titleEn) {
                    return this.item.titleEn.length > 0
                } else {
                    return false
                }
            },
            descriptionState() {
                if (this.item.description) {
                    return this.item.description.length > 0
                } else {
                    return false
                }
            },
            descriptionEnState() {
                if (this.item.descriptionEn) {
                    return this.item.descriptionEn.length > 0
                } else {
                    return false
                }
            },
            validated() {
                return !this.nameState || !this.orderState || !this.titleState || !this.titleEnState || !this.descriptionState || !this.descriptionEnState
            }
        },
        data() {
            return {
                lang: Const.LANG,
                item: {},
                itemName: ''
            }
        },
        mounted() {
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/productCategory/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.itemName = JSON.parse(JSON.stringify(this.item.name))
                })
            } else {
                this.item = {
                    name: '',
                    itemOrder: '',
                    title: '',
                    titleEn: '',
                    description: '',
                    descriptionEn: '',
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/product-categories')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/productCategory/save', {
                    id: this.item.id,
                    name: this.item.name,
                    itemOrder: this.item.itemOrder,
                    title: this.item.title,
                    titleEn: this.item.titleEn,
                    description: this.item.description,
                    descriptionEn: this.item.descriptionEn,
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/product-categories')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/productCategory/delete', {
                    data: {
                        id: this.item.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/product-categories')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

</style>
