<template>
    <section class="about-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">{{ $t('createNewTitle') }}</h4>
                        <h4 v-else class="card-title">{{ $t('editEntry') }} <strong>{{ aboutItemName }}</strong> <code>(id {{ aboutItem.id }})</code></h4>
                        <p class="card-description pb-4">{{ $t('fillFieldsBelow') }}</p>
                        <div class="forms-sample">
                            <b-form-group :label="$t('forms.name')" label-for="name-input">
                                <b-form-input type="text" id="name-input" :placeholder="$t('forms.name')" v-model="aboutItem.name" :state="nameState"/>
                            </b-form-group>

                            <b-form-group :label="$t('forms.title')" label-for="title-input" class="mb-4">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="title-input" :placeholder="$t('forms.title') + ' (' + lang.toUpperCase() + ')'" v-model="aboutItem.title" :state="titleState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="titleEn-input" :placeholder="$t('forms.title') + ' (EN)'" v-model="aboutItem.titleEn" :state="titleEnState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group :label="$t('forms.description')" label-for="summary-input" class="mb-4">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="summary-input" :placeholder="$t('forms.description') + ' (' + lang.toUpperCase() + ')'" v-model="aboutItem.summary" :state="summaryState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="summaryEn-input" :placeholder="$t('forms.description') + ' (EN)'" v-model="aboutItem.summaryEn" :state="summaryEnState"/></div>
                                </div>
                            </b-form-group>

                            <div class="row">
                                <div class="col-lg-2">
                                    <b-form-group :label="$t('forms.order')" label-for="order-input">
                                        <b-form-input type="number" id="order-input" :placeholder="$t('forms.order')"
                                                      v-model="aboutItem.itemOrder" :state="orderState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-10">
                                    <b-form-group :label="$t('forms.image')" label-for="image-input">
                                        <b-button id="image-input"
                                                  :variant="aboutItem.thumbnail && aboutItem.thumbnail.id ? 'outline-success' : 'outline-danger'"
                                                  @click="openImagePicker('image-input')">
                                            {{ aboutItem.thumbnail && aboutItem.thumbnail.id ? aboutItem.thumbnail.filename.substr(8) : $t('forms.select') }}
                                        </b-button>
                                        <b-popover v-if="aboutItem.thumbnail && aboutItem.thumbnail.id"
                                                   target="image-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="aboutItem.thumbnail.filename"
                                                                    class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">{{ $t('forms.imageSize') }}: {{ aboutItem.thumbnail.width }} x
                                                {{ aboutItem.thumbnail.height }}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">{{ $t('forms.save') }}
                            </b-button>
                            <b-button variant="light" v-on:click="cancel">{{ $t('forms.cancel') }}</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right"
                                      v-b-modal.confirm-delete>{{ $t('forms.delete') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light"
                 :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.delete')" @ok="deleteItem">
            <p class="text-center">{{ $t('forms.deleteConfirmationMessage') }}</p>
        </b-modal>

        <b-modal id="image-picker" :title="$t('forms.imagePickerTitle')" size="xl" header-bg-variant="primary" header-text-variant="light"
                 :okTitle="$t('forms.close')" ok-only>
            <div class="row images">
                <div class="col-lg-1 my-auto text-center p-1" v-for="image in images" :key="image.id"
                     :id="'image-wrapper-' + image.id">
                    <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image"
                         @click="pasteImage(image.id)">
                    <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                        <div class="row">
                            <div class="col text-center">
                                <span><em>{{ image.filename.substr(8) }}</em></span><br>
                                <span class="p-0">{{ image.width + ' x ' + image.height }}</span>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>
        </b-modal>
    </section>
</template>

<script>
import axios from 'axios'
import {Const} from '../../../main'

export default {
    name: 'AboutInfo',
    computed: {
        nameState() {
            if (this.aboutItem.name) {
                return this.aboutItem.name.length > 0
            } else {
                return false
            }
        },
        titleState() {
            if (this.aboutItem.title) {
                return this.aboutItem.title.length > 0
            } else {
                return false
            }
        },
        titleEnState() {
            if (this.aboutItem.titleEn) {
                return this.aboutItem.titleEn.length > 0
            } else {
                return false
            }
        },
        summaryState() {
            if (this.aboutItem.summary) {
                return this.aboutItem.summary.length > 0
            } else {
                return false
            }
        },
        summaryEnState() {
            if (this.aboutItem.summaryEn) {
                return this.aboutItem.summaryEn.length > 0
            } else {
                return false
            }
        },
        orderState() {
            if (this.aboutItem.itemOrder) {
                return this.aboutItem.itemOrder > 0
            } else {
                return false
            }
        },
        thumbnailState() {
            return this.aboutItem.thumbnail && this.aboutItem.thumbnail.id > 0
        },
        validated() {
            return !this.nameState || !this.titleState || !this.titleEnState || !this.summaryState ||
                !this.summaryEnState || !this.orderState || !this.thumbnailState
        }
    },
    data() {
        return {
            lang: Const.LANG,
            images: [],
            aboutItem: {},
            aboutItemName: '',
            imagePickerContext: ''
        }
    },
    mounted() {
        if (this.$attrs.mode === Const.MODE_EDIT) {
            axios.get(Const.API_HOST + '/admin/aboutItem/get', {
                params: {
                    'id': this.$route.params.id
                }
            }).then(function (response) {
                return response.data
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.aboutItem = body
                this.aboutItemName = JSON.parse(JSON.stringify(this.aboutItem.name))
            })
        } else {
            this.aboutItem = {
                id: '',
                name: '',
                title: '',
                titleEn: '',
                summary: '',
                summaryEn: '',
                thumbnail: {},
                itemOrder: '',
            }
        }
    },
    methods: {
        cancel() {
            this.$router.push('/about-items')
        },
        saveItem() {
            var app = this
            axios.post(Const.API_HOST + '/admin/aboutItem/save', {
                aboutItem: {
                    id: this.aboutItem.id,
                    name: this.aboutItem.name,
                    title: this.aboutItem.title,
                    titleEn: this.aboutItem.titleEn,
                    summary: this.aboutItem.summary,
                    summaryEn: this.aboutItem.summaryEn,
                    thumbnail: this.aboutItem.thumbnail,
                    itemOrder: this.aboutItem.itemOrder,
                }
            }).then(function (response) {
                // eslint-disable-next-line no-console
                console.log(response)
                if (response.status === 200) {
                    app.$router.push('/about-items')
                }
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            })
        },
        deleteItem() {
            var app = this
            axios.delete(Const.API_HOST + '/admin/aboutItem/delete', {
                data: {
                    id: this.aboutItem.id
                }
            }).then(function (response) {
                // eslint-disable-next-line no-console
                console.log(response)
                if (response.status === 200) {
                    app.$router.push('/about-items')
                }
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            })
        },
        openImagePicker(context) {
            this.imagePickerContext = context
            this.$bvModal.show('image-picker')
            axios.get(Const.API_HOST + '/admin/images')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.images = body
            })
        },
        pasteImage(id) {
            var selected = this.images.find(obj => {
                return obj.id === id
            })

            // eslint-disable-next-line no-console
            console.log(selected.filename)

            if (this.imagePickerContext === 'image-input') {
                this.aboutItem.thumbnail = JSON.parse(JSON.stringify(selected))
            }

            this.$bvModal.hide('image-picker')
        }
    }
}
</script>

<style scoped>
.clickable /deep/ tbody td {
    cursor: pointer;
}

.browser-image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}

/deep/ .tox-editor-container div:first-child {
    top: 58px !important;
}
</style>
