<template>
    <section class="document">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">{{ $t('createNewTitle') }}</h4>
                        <h4 v-else class="card-title">{{ $t('editEntry') }} <strong>{{ documentName }}</strong> <code>(id {{ document.id }})</code></h4>
                        <p class="card-description pb-4">{{ $t('fillFieldsBelow') }}</p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-7">
                                    <b-form-group :label="$t('forms.name')" label-for="name-input">
                                        <b-form-input type="text" id="name-input" :placeholder="$t('forms.name')"
                                                      v-model="document.name" :state="nameState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-5">
                                    <b-form-group :label="$t('forms.key')" label-for="key-input">
                                        <b-form-input type="text" id="title-input" :placeholder="$t('forms.key')"
                                                      v-model="document.key" :state="keyState" disabled/>
                                    </b-form-group>
                                </div>
                            </div>
                            <b-form-group :label="$t('forms.file')" label-for="file-input" class="pt-3">
                                <div class="row align-items-center pl-2">
                                    <b-button id="file-input"
                                              :variant="document.file && document.file.id ? 'outline-success' : 'outline-primary'"
                                              @click="openFilePicker('file-input')">
                                        {{ document.file && document.file.id ? $t('forms.change') : $t('forms.select') }}
                                    </b-button>
                                    <div class="col">
                                        <b-form-input type="text" id="file-input" placeholder="-"
                                                      :value="document.file && document.file.filename ? document.file.filename.substr(7) : ''"
                                                      disabled/>
                                    </div>
                                </div>
                            </b-form-group>
                        </div>

                        <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">{{ $t('forms.save') }}
                        </b-button>
                        <b-button variant="light" v-on:click="cancel">{{ $t('forms.cancel') }}</b-button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light"
                 :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.delete')" @ok="deleteItem">
            <p class="text-center">{{ $t('forms.deleteConfirmationMessage') }}</p>
        </b-modal>

        <b-modal id="file-picker" :title="$t('forms.filePickerTitle')" size="xl" header-bg-variant="primary" header-text-variant="light"
                 :okTitle="$t('forms.close')" ok-only>
            <b-table striped hover small :items="files" :fields="fileFields" @row-clicked="pasteFile"
                     class="clickable"></b-table>
        </b-modal>
    </section>
</template>

<script>
import axios from 'axios'
import {Const} from '../../../main'
import moment from "moment";

export default {
    name: 'AboutInfo',
    computed: {
        nameState() {
            if (this.document.name) {
                return this.document.name.length > 0
            } else {
                return false
            }
        },
        keyState() {
            if (this.document.key) {
                return this.document.key.length > 0
            } else {
                return false
            }
        },
        fileState() {
            return this.document.file && this.document.file.id > 0
        },
        validated() {
            return !this.nameState || !this.keyState || !this.fileState
        }
    },
    data() {
        return {
            files: [],
            document: {},
            documentName: '',
            imagePickerContext: '',
            fileFields: [
                {
                    key: 'id',
                    label: 'ID',
                    sortable: true,
                },
                {
                    key: 'filename',
                    label: this.$t('forms.name'),
                    sortable: true,
                    formatter: value => {
                        return this.trimEllip(value.substr(7), 120)
                    }
                },
                {
                    key: 'uploadDate',
                    label: this.$t('forms.created'),
                    sortable: true,
                    formatter: value => {
                        return moment(value).format('DD.MM.YYYY, HH:mm');
                    }
                }
            ]
        }
    },
    mounted() {
        if (this.$attrs.mode === Const.MODE_EDIT) {
            axios.get(Const.API_HOST + '/admin/document/get', {
                params: {
                    'id': this.$route.params.id
                }
            }).then(function (response) {
                return response.data
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.document = body
                this.documentName = JSON.parse(JSON.stringify(this.document.name))
            })
        } else {
            this.document = {
                id: '',
                name: '',
                key: '',
                file: {},
            }
        }
    },
    methods: {
        trimEllip(s, length) {
            return s.length > length ? s.substring(0, length) + "..." : s;
        },
        cancel() {
            this.$router.push('/documents')
        },
        saveItem() {
            var app = this
            axios.post(Const.API_HOST + '/admin/document/save', {
                document: {
                    id: this.document.id,
                    name: this.document.name,
                    key: this.document.key,
                    file: this.document.file,
                }
            }).then(function (response) {
                // eslint-disable-next-line no-console
                console.log(response)
                if (response.status === 200) {
                    app.$router.push('/documents')
                }
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            })
        },
        deleteItem() {
            var app = this
            axios.delete(Const.API_HOST + '/admin/document/delete', {
                data: {
                    id: this.document.id
                }
            }).then(function (response) {
                // eslint-disable-next-line no-console
                console.log(response)
                if (response.status === 200) {
                    app.$router.push('/documents')
                }
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            })
        },
        openFilePicker(context) {
            this.filePickerContext = context
            this.$bvModal.show('file-picker')
            axios.get(Const.API_HOST + '/admin/files')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.files = body
            })
        },
        pasteFile(item) {
            // eslint-disable-next-line no-console
            console.log(item)
            if (this.filePickerContext === 'file-input') {
                this.document.file = JSON.parse(JSON.stringify(item))
            }

            this.$bvModal.hide('file-picker')
            this.files = []
        },
    }
}
</script>

<style scoped>
.clickable /deep/ tbody td {
    cursor: pointer;
}

.browser-image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}

/deep/ .tox-editor-container div:first-child {
    top: 58px !important;
}
</style>
