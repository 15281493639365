<template lang="html">

  <section class="login">
    <div class="wrapper d-flex align-items-center auth login-full-bg">
      <div class="row w-100">
        <div class="col-lg-6 mx-auto">
          <div class="auth-form-dark text-left p-5">
            <h2>{{ $t('signin') }}</h2>
            <h4 class="pb-3 font-weight-light">{{ $t('signinDescription') }}</h4>
            <b-alert class="mt-alert" v-model="showInvalidCredentialAlert" dismissible>
              {{ $t('invalidCredential') }}
            </b-alert>
            <form class="pt-3">
              <form>
                <div class="form-group">
                  <label for="inputUsername">{{ $t('forms.email') }}</label>
                  <input type="text" v-model="credential.username" class="form-control" id="inputUsername" aria-describedby="emailHelp">
                  <i class="mdi mdi-account"></i>
                </div>
                <div class="form-group">
                  <label for="inputPassword">{{ $t('forms.password') }}</label>
                  <input type="password" v-model="credential.password" class="form-control" id="inputPassword">
                  <i class="mdi mdi-eye cursor-pointer" @mousedown="showPass" @mouseup="hidePass"></i>
                </div>
                <div class="mt-5">
                  <a v-on:click="login" class="btn btn-block btn-warning btn-lg font-weight-medium" to="/">{{ $t('signin') }}</a>
                </div>
              </form>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script lang="js">
import {mapGetters} from "vuex";

export default {
  name: 'Login',
  computed: mapGetters([
    'isLogged'
  ]),
  data() {
    return {
      showInvalidCredentialAlert: false,
      credential: {
        username:'',
        password:''
      }
    }
  },
  mounted() {
    // eslint-disable-next-line no-console
    console.log(this.$route.query.invalidCredentials)
    if (this.$route.query.invalidCredentials)
      this.showInvalidCredentialAlert = true

    if (this.isLogged)
      this.$router.push("/")
  },
  methods: {
    login() {
      this.$store.dispatch('login', this.credential);
    },
    showPass() {
        document.getElementById("inputPassword").type = "text";
    },
    hidePass() {
        document.getElementById("inputPassword").type = "password";
    }
  },
}
</script>

<style scoped lang="scss">
  .login, .wrapper {
    min-height: 100vh;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .mt-alert {
    border-color: rgba(94, 32, 32, 0.85);
    border-radius: 0;
    background-color: rgba(137, 52, 52, 0.52);
    color: white;
  }
</style>
