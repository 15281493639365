<template>
    <section class="page-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">{{ $t('createNewTitle') }}</h4>
                        <h4 v-else class="card-title">{{ $t('editEntry') }} <strong>{{infoName}}</strong> <code>(id {{info.id}})</code></h4>
                        <p class="card-description pb-4">{{ $t('fillFieldsBelow') }}</p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-6">
                                    <b-form-group :label="$t('forms.name')" label-for="name-input">
                                        <b-form-input type="text" id="name-input" :placeholder="$t('forms.name')" v-model="info.name" :state="nameState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-6">
                                    <b-form-group label="URL" label-for="url-input">
                                        <b-form-input type="text" id="url-input" placeholder="URL" v-model="info.url" :state="urlState"/>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group :label="$t('forms.title')" label-for="title-input" class="mb-4">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="title-input" :placeholder="$t('forms.title') + ' (' + lang.toUpperCase() + ')'" v-model="info.title" :state="titleState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="titleEn-input" :placeholder="$t('forms.title') + ' (EN)'" v-model="info.titleEn" :state="titleEnState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group :label="$t('forms.description')" label-for="description-input" class="mb-4">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="description-input" :placeholder="$t('forms.description') + ' (' + lang.toUpperCase() + ')'" v-model="info.pageDescription" :state="descriptionState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="descriptionEn-input" :placeholder="$t('forms.description') + ' (EN)'" v-model="info.pageDescriptionEn" :state="descriptionEnState"/></div>
                                </div>
                            </b-form-group>

                            <div class="row">
                                <div class="col-lg-2">
                                    <b-form-group :label="$t('forms.author')" label-for="author-input">
                                        <b-form-input type="text" id="author-input" :placeholder="$t('forms.author')" v-model="info.author" :state="authorState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-10">
                                    <b-form-group :label="$t('forms.mainImage')" label-for="image-input">
                                        <b-button id="image-input" :variant="info.image && info.image.id ? 'outline-success' : 'outline-danger'" @click="openImagePicker('image-input')">
                                            {{info.image && info.image.id ? info.image.filename.substr(8) : $t('forms.select')}}
                                        </b-button>
                                        <b-popover v-if="info.image && info.image.id" target="image-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="info.image.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">{{ $t('forms.imageSize') }}: {{info.image.width}} x
                                                {{info.image.height}}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group :label="$t('forms.content') + ' (' + lang.toUpperCase() + ')'" label-for="tinymce-input">
                                <editor ref="formEditor" id="tinymce-input" :api-key="editorApiKey" :init="editorConf" v-model="info.summary"/>
                            </b-form-group>

                            <b-form-group :label="$t('forms.content') + ' (EN)'" label-for="tinymceEn-input">
                                <editor ref="formEditorEn" id="tinymceEn-input" :api-key="editorApiKey" :init="editorConfEn" v-model="info.summaryEn"/>
                            </b-form-group>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">{{ $t('forms.save') }}</b-button>
                            <b-button variant="light" v-on:click="cancel">{{ $t('forms.cancel') }}</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>{{ $t('forms.delete') }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light" :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.delete')" @ok="deleteItem">
            <p class="text-center">{{ $t('forms.deleteConfirmationMessage') }}</p>
        </b-modal>

        <b-modal id="image-picker" :title="$t('forms.imagePickerTitle')" size="xl" header-bg-variant="primary" header-text-variant="light" :okTitle="$t('forms.close')" ok-only>
            <div class="row images">
                <div class="col-lg-1 my-auto text-center p-1" v-for="image in images" :key="image.id" :id="'image-wrapper-' + image.id">
                    <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image" @click="pasteImage(image.id)">
                    <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                        <div class="row">
                            <div class="col text-center">
                                <span><em>{{image.filename.substr(8)}}</em></span><br>
                                <span class="p-0">{{image.width + ' x ' + image.height}}</span>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    var Editor = require('@tinymce/tinymce-vue').default;
    var app = null

    export default {
        name: 'Info',
        components: {
            'editor': Editor
        },
        computed: {
            nameState() {
                if (this.info.name) {
                    return this.info.name.length > 0
                } else {
                    return false
                }
            },
            titleState() {
                if (this.info.title) {
                    return this.info.title.length > 0
                } else {
                    return false
                }
            },
            titleEnState() {
                if (this.info.titleEn) {
                    return this.info.titleEn.length > 0
                } else {
                    return false
                }
            },
            descriptionState() {
                if (this.info.pageDescription) {
                    return this.info.pageDescription.length > 0
                } else {
                    return false
                }
            },
            descriptionEnState() {
                if (this.info.pageDescriptionEn) {
                    return this.info.pageDescriptionEn.length > 0
                } else {
                    return false
                }
            },
            urlState() {
                if (this.info.url) {
                    return this.info.url.length > 0 &&
                        this.info.url.startsWith('/') &&
                        this.info.url.split('/').length === 2
                } else {
                    return false
                }
            },
            imageState() {
                return this.info.image && this.info.image.id > 0
            },
            authorState() {
              if (this.info.author) {
                return this.info.author.length > 0
              } else {
                return false
              }
            },
            validated() {
                return !this.nameState || !this.titleState || !this.titleEnState || !this.descriptionState ||
                    !this.descriptionEnState || !this.urlState || !this.imageState || !this.authorState
            }
        },
        data() {
            return {
                lang: Const.LANG,
                images: [],
                info: {},
                infoName: '',
                editorApiKey: "jdsjwlv71ngv4ngm4fca30grz3lu6w833qma1br216ewfsqh",
                editorConf: {
                    language: Const.LANG,
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: this.$t('forms.writeHere'),
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymce-input')
                        });
                    }
                },
                editorConfEn: {
                    language: 'en',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: this.$t('forms.writeHere'),
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('tinymceEn-input')
                        });
                    }
                },
                imagePickerContext: ''
            }
        },
        mounted() {
            app = this
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/info/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.info = body
                    this.infoName = JSON.parse(JSON.stringify(this.info.name))
                })
            } else {
                this.info = {
                    name: '',
                    title: '',
                    titleEn: '',
                    pageDescription: '',
                    pageDescriptionEn: '',
                    image: {},
                    summary: '',
                    summaryEn: '',
                    url: '',
                    author: ''
                }
            }
        },
        methods: {
            cancel() {
                this.$router.push('/infos')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/info/save', {
                    info: {
                        id: this.info.id,
                        name: this.info.name,
                        title: this.info.title,
                        titleEn: this.info.titleEn,
                        pageDescription: this.info.pageDescription,
                        pageDescriptionEn: this.info.pageDescriptionEn,
                        image: this.info.image,
                        summary: this.info.summary,
                        summaryEn: this.info.summaryEn,
                        url: this.info.url,
                        author: this.info.author
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/infos')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/info/delete', {
                    data: {
                        id: this.info.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/infos')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            openImagePicker(context) {
                this.imagePickerContext = context
                this.$bvModal.show('image-picker')
                axios.get(Const.API_HOST + '/admin/images')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.images = body
                })
            },
            pasteImage(id) {
                var selected = this.images.find(obj => {
                    return obj.id === id
                })

                // eslint-disable-next-line no-console
                console.log(selected.filename)
                let html = '<p class="par-with-images"><img src="' + selected.filename + '" alt="' + selected.alt + '" width="' + selected.width + 'px" height="' + selected.height + 'px"></p>';

                if (this.imagePickerContext === 'tinymce-input') {
                    this.$refs.formEditor.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'tinymceEn-input') {
                    this.$refs.formEditorEn.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'image-input') {
                    this.info.image = JSON.parse(JSON.stringify(selected))
                }

                this.$bvModal.hide('image-picker')
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

    .browser-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    /deep/ .tox-editor-container div:first-child {
        top: 58px !important;
    }
</style>
