<template>
    <section class="product-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 v-if="$attrs.mode === 'new'">{{ $t('createNewTitle') }}</h4>
                        <h4 v-else class="card-title">{{ $t('editEntry') }} <strong>{{itemName}}</strong> <code>(id {{item.id}})</code></h4>
                        <p class="card-description pb-4">{{ $t('fillFieldsBelow') }}</p>
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-2">
                                    <b-form-group :label="$t('forms.category')" label-for="category-input">
                                        <b-form-select v-model="item.category.id" :options="categories" value-field="id" text-field="name" :state="categoryState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2">
                                    <b-form-group :label="$t('forms.order')" label-for="order-input">
                                        <b-form-input type="number" id="order-input" :placeholder="$t('forms.order')" v-model="item.itemOrder" :state="orderState" min="1"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group label="URL" label-for="url-input">
                                        <b-form-input type="text" id="url-input" placeholder="URL" v-model="item.url" :state="urlState"/>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-2 my-auto">
                                    <b-form-group :label="$t('forms.buyPossibility')" label-for="category-input">
                                        <b-form-checkbox v-model="item.canBuy" name="check-button" switch size="lg">
                                            <p v-text="item.canBuy ? $t('forms.yes') : $t('forms.no')" style="margin-top: 4px;" class="pl-2" />
                                        </b-form-checkbox>
                                    </b-form-group>
                                </div>
                            </div>
                            <b-form-group :label="$t('forms.name')" label-for="name-input" class="mb-4">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="name-input" :placeholder="$t('forms.name') + ' (' + lang.toUpperCase() + ')'" v-model="item.name" :state="nameState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="nameEn-input" :placeholder="$t('forms.name') + ' (EN)'" v-model="item.nameEn" :state="nameEnState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group :label="$t('forms.title')" label-for="title-input" class="mb-4">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="title-input" :placeholder="$t('forms.title') + ' (' + lang.toUpperCase() + ')'" v-model="item.title" :state="titleState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="titleEn-input" :placeholder="$t('forms.title') + ' (EN)'" v-model="item.titleEn" :state="titleEnState"/></div>
                                </div>
                            </b-form-group>

                            <b-form-group :label="$t('forms.description')" label-for="description-input" class="mb-4">
                                <div class="row align-items-center">
                                    <div class="col-auto text-right min-45px-w">{{ lang.toUpperCase() }}:</div>
                                    <div class="col"><b-form-input type="text" id="description-input" :placeholder="$t('forms.description') + ' (' + lang.toUpperCase() + ')'" v-model="item.description" :state="descriptionState"/></div>
                                </div>
                                <div class="row align-items-center mt-1">
                                    <div class="col-auto text-right min-45px-w">EN:</div>
                                    <div class="col"><b-form-input type="text" id="descriptionEn-input" :placeholder="$t('forms.description') + ' (EN)'" v-model="item.descriptionEn" :state="descriptionEnState"/></div>
                                </div>
                            </b-form-group>

                            <div v-if="newFeatures != null" class="row m-0 p-0 mt-4 mb-1">
                                <p>{{ $t('forms.productFeatures') }}</p>
                                <b-table responsive hover :items="newFeatures" :fields="featuresFields" small class="pt-0 mt-0 pb-0 mb-0">
                                    <template v-slot:cell(name)="row">
                                        <b-form-input :state="featureNameInputValidation(row.index)" v-model="row.item.name"/>
                                    </template>
                                    <template v-slot:cell(nameEn)="row">
                                        <b-form-input :state="featureNameEnInputValidation(row.index)" v-model="row.item.nameEn"/>
                                    </template>
                                    <template v-slot:cell(delete)="row">
                                        <b-button variant="danger" @click="deleteFeatureRow(row.index)">{{ $t('forms.delete').toLowerCase() }}</b-button>
                                    </template>
                                </b-table>
                            </div>
                            <div class="row mt-1 mr-0 mb-2 pt-0">
                                <div class="col mr-0 pr-1">
                                    <b-button variant="primary" class="float-right" @click="addFeatureRow">{{ $t('forms.add').toLowerCase() }}</b-button>
                                </div>
                            </div>

                            <div v-if="newAS != null" class="row m-0 p-0 mb-1">
                                <p>{{ $t('forms.productVases') }}</p>
                                <b-table responsive hover :items="newAS" :fields="asFields" small class="pt-0 mt-0 pb-0 mb-0">
                                    <template v-slot:cell(name)="row">
                                        <b-form-input :state="nameInputValidation(row.index)" v-model="row.item.name"/>
                                    </template>
                                    <template v-slot:cell(nameEn)="row">
                                        <b-form-input :state="nameEnInputValidation(row.index)" v-model="row.item.nameEn"/>
                                    </template>
                                    <template v-slot:cell(netPrice)="row">
                                        <b-form-input :state="netPriceValidation(row.index)" v-model="row.item.netPrice" type="number"/>
                                    </template>
                                    <template v-slot:cell(serviceType)="row">
                                        <b-form-select v-model="row.item.serviceType" :options="options"/>
                                    </template>
                                    <template v-slot:cell(serviceOrder)="row">
                                        <b-form-input :state="serviceOrderValidation(row.index)" v-model="row.item.serviceOrder" type="number"/>
                                    </template>
                                    <template v-slot:cell(delete)="row">
                                        <b-button variant="danger" @click="deleteAdditionalServiceRow(row.index)">{{ $t('forms.delete').toLowerCase() }}</b-button>
                                    </template>
                                </b-table>
                            </div>
                            <div class="row mt-1 mr-0 mb-5 pt-0">
                                <div class="col mr-0 pr-1">
                                    <b-button variant="primary" class="float-right" @click="addAdditionalServiceRow">{{ $t('forms.add').toLowerCase() }}</b-button>
                                </div>
                            </div>

                            <div class="row mt-12 pt-5">
                                <div class="col-lg-4">
                                    <b-form-group :label="$t('forms.thumbnail')" label-for="thumbnail-input">
                                        <b-button id="thumbnail-input" :variant="item.thumbnail && item.thumbnail.id ? 'outline-success' : 'outline-danger'" @click="openImagePicker('thumbnail-input')" :state="thumbnailState">
                                            {{item.thumbnail && item.thumbnail.id ? item.thumbnail.filename.substr(8) : $t('forms.select')}}
                                        </b-button>
                                        <b-popover v-if="item.thumbnail && item.thumbnail.id" target="thumbnail-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="item.thumbnail.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">{{ $t('forms.imageSize') }}: {{item.thumbnail.width}} x {{item.thumbnail.height}}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group :label="$t('forms.mainImage')" label-for="image-input">
                                        <b-button id="image-input" :variant="item.image && item.image.id ? 'outline-success' : 'outline-danger'" @click="openImagePicker('image-input')">
                                            {{item.image && item.image.id ? item.image.filename.substr(8) : $t('forms.select')}}
                                        </b-button>
                                        <b-popover v-if="item.image && item.image.id" target="image-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="item.image.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">{{ $t('forms.imageSize') }}: {{item.image.width}} x {{item.image.height}}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                                <div class="col-lg-4">
                                    <b-form-group :label="$t('forms.image360')" label-for="image360-input">
                                        <b-button id="image360-input" :variant="item.image360 && item.image360.id ? 'outline-success' : 'outline-primary'" @click="openImagePicker('image360-input')">
                                            {{item.image360 && item.image360.id ? item.image360.filename.substr(8) : $t('forms.select')}}
                                        </b-button>
                                        <b-popover v-if="item.image360 && item.image360.id" target="image360-input" triggers="hover" placement="right">
                                            <p class="m-0 p-0"><img :src="item.image360.filename" class="browser-image"></p>
                                            <p class="m-0 p-0 text-center">{{ $t('forms.imageSize') }}: {{item.image360.width}} x {{item.image360.height}}</p>
                                        </b-popover>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group :label="$t('forms.productDatasheet')" label-for="datasheet-input" class="pt-3">
                                <div class="row align-items-center pl-2">
                                    <b-button id="datasheet-input" :variant="item.datasheet && item.datasheet.id ? 'outline-success' : 'outline-primary'" @click="openFilePicker('datasheet-input')">
                                        {{item.datasheet && item.datasheet.id ? $t('forms.change') : $t('forms.select')}}
                                    </b-button>
                                    <div class="col"><b-form-input type="text" id="datasheet-input" placeholder="-" :value="item.datasheet && item.datasheet.filename ? item.datasheet.filename.substr(7) : ''" disabled/></div>
                                </div>
                            </b-form-group>

                            <b-form-group :label="$t('forms.description') + ' (' + lang.toUpperCase() + ')'" label-for="summary-input" class="pt-3">
                                <editor ref="formEditor" id="summary-input" :api-key="editorApiKey" :init="editorConf" v-model="item.summary"/>
                            </b-form-group>

                            <b-form-group :label="$t('forms.description') + ' (EN)'" label-for="summaryEn-input" class="pt-3">
                                <editor ref="formEditorEn" id="summaryEn-input" :api-key="editorApiKey" :init="editorConfEn" v-model="item.summaryEn"/>
                            </b-form-group>

                            <b-button variant="success" class="mr-2" @click="saveItem" :disabled="validated">{{ $t('forms.save') }}</b-button>
                            <b-button variant="light" v-on:click="cancel">{{ $t('forms.cancel') }}</b-button>
                            <b-button v-if="$attrs.mode === 'edit'" variant="danger" class="float-right" v-b-modal.confirm-delete>{{ $t('forms.delete') }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="confirm-delete" :title="$t('forms.warning')" size="md" header-bg-variant="danger" header-text-variant="light" :cancelTitle="$t('forms.cancel')" :okTitle="$t('forms.delete')" @ok="deleteItem">
            <p class="text-center">{{ $t('forms.deleteConfirmationMessageExt') }}</p>
        </b-modal>

        <b-modal id="image-picker" :title="$t('forms.imagePickerTitle')" size="xl" header-bg-variant="primary" header-text-variant="light" :okTitle="$t('forms.close')" ok-only>
            <div class="row images">
                <div class="col-lg-1 my-auto text-center p-1" v-for="image in images" :key="image.id" :id="'image-wrapper-' + image.id">
                    <img :id="image.id" :src="image.filename" :alt="image.alt" class="browser-image" @click="pasteImage(image.id)">
                    <b-popover :target="'image-wrapper-' + image.id" triggers="hover" placement="top">
                        <div class="row">
                            <div class="col text-center">
                                <span><em>{{image.filename.substr(8)}}</em></span><br>
                                <span class="p-0">{{image.width + ' x ' + image.height}}</span>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>
        </b-modal>
        <b-modal id="file-picker" :title="$t('forms.filePickerTitle')" size="xl" header-bg-variant="primary" header-text-variant="light" :okTitle="$t('forms.close')" ok-only>
            <b-table striped hover small :items="files" :fields="fileFields" @row-clicked="pasteFile" class="clickable"></b-table>
        </b-modal>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    import moment from "moment/moment";
    var Editor = require('@tinymce/tinymce-vue').default;
    var app = null

    export default {
        name: 'ProductItem',
        components: {
            'editor': Editor
        },
        computed: {
            nameState() {
                if (this.item.name) {
                    return this.item.name.length > 0
                } else {
                    return false
                }
            },
            nameEnState() {
                if (this.item.nameEn) {
                    return this.item.nameEn.length > 0
                } else {
                    return false
                }
            },
            titleState() {
                if (this.item.title) {
                    return this.item.title.length > 0
                } else {
                    return false
                }
            },
            titleEnState() {
                if (this.item.titleEn) {
                    return this.item.titleEn.length > 0
                } else {
                    return false
                }
            },
            descriptionState() {
                if (this.item.description) {
                    return this.item.description.length > 0
                } else {
                    return false
                }
            },
            descriptionEnState() {
                if (this.item.descriptionEn) {
                    return this.item.descriptionEn.length > 0
                } else {
                    return false
                }
            },
            urlState() {
                if (this.item.url) {
                    return this.item.url.length > 0 &&
                        this.item.url.startsWith('/') &&
                        this.item.url.split('/').length === 2
                } else {
                    return false
                }
            },
            orderState() {
                if (this.item.itemOrder) {
                    return this.item.itemOrder > 0
                } else {
                    return false
                }
            },
            categoryState() {
                if (this.item.category) {
                    return this.item.category.id > 0
                } else {
                    return false
                }
            },
            featureState() {
                return this.item.features.indexOf(this.newTag.trim()) > -1 ? false : null
            },
            thumbnailState() {
                return this.item.thumbnail && this.item.thumbnail.id > 0
            },
            imageState() {
                return this.item.image && this.item.image.id > 0
            },
            validateAdditionalServices() {
                if (this.newAS == null) {
                    return true;
                }
                for (let i = 0; i < this.newAS.length; i++) {
                    if (this.newAS[i].name === undefined
                        || this.newAS[i].name.length < 5
                        || !(this.newAS[i].netPrice >= 0)
                        || !(this.newAS[i].serviceOrder > 0)) {
                        return false;
                    }
                }
                return true;
            },
            validateFeatures() {
                return this.newFeatures.length !== 0;

            },
            validated() {
                return !this.nameState || !this.nameEnState || !this.titleState || !this.titleEnState ||
                    !this.descriptionState || !this.descriptionEnState || !this.urlState || !this.categoryState ||
                    !this.orderState || !this.thumbnailState || !this.imageState || !this.validateAdditionalServices ||
                    !this.validateFeatures
            }
        },
        data() {
            return {
                lang: Const.LANG,
                categories: [],
                images: [],
                files: [],
                item: {},
                itemName: '',
                newTag: '',
                itemFeatures: [],
                editorApiKey: "jdsjwlv71ngv4ngm4fca30grz3lu6w833qma1br216ewfsqh",
                editorConf: {
                    language: Const.LANG,
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: this.$t('forms.writeHere'),
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('summary-input')
                        });
                    }
                },
                editorConfEn: {
                    language: 'en',
                    min_height: 500,
                    menubar: false,
                    convert_urls: false,
                    toolbar_sticky: true,
                    plugins: [
                        'lists link image paste wordcount code autoresize'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link customImage | code',
                    block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                    contextmenu: "link image imagetools table",
                    autoresize_bottom_margin: 100,
                    placeholder: this.$t('forms.writeHere'),
                    setup: function (editor) {
                        editor.ui.registry.addButton('customImage', {
                            icon: 'image',
                            onAction: () => app.openImagePicker('summaryEn-input')
                        });
                    }
                },
                imagePickerContext: '',
                filePickerContext: '',
                featuresFields: [
                    {
                        key: 'name',
                        label: this.$t('forms.name') + ' (' + Const.LANG.toUpperCase() + ')',
                    },
                    {
                        key: 'nameEn',
                        label: this.$t('forms.name') + ' (EN)',
                    },
                    {
                        key: 'delete',
                        label: '',
                        thStyle: 'width: 80px;'
                    }
                ],
                asFields: [
                    {
                        key: 'name',
                        label: this.$t('forms.name') + ' (' + Const.LANG.toUpperCase() + ')',
                    },
                    {
                        key: 'nameEn',
                        label: this.$t('forms.name') + ' (EN)',
                    },
                    {
                        key: 'serviceType',
                        label: this.$t('forms.feeType'),
                        thStyle: 'width: 150px;'

                    },
                    {
                        key: 'serviceOrder',
                        label: this.$t('forms.order'),
                        thStyle: 'width: 150px;'
                    },
                    {
                        key: 'netPrice',
                        label: this.$t('forms.netPrice'),
                        thStyle: 'width: 150px;'
                    },
                    {
                        key: 'delete',
                        label: '',
                        thStyle: 'width: 80px;'
                    }
                ],
                newFeatures: [],
                newAS: [],
                options: [
                    { value: "ONE_TIME_FEE", text: this.$t('forms.feeTypeOneTime') },
                    { value: "RECURRING_FEE", text: this.$t('forms.feeTypeRecurring') },
                ],
                fileFields: [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true,
                    },
                    {
                        key: 'filename',
                        label: this.$t('forms.name'),
                        sortable: true,
                        formatter: value => {
                            return this.trimEllip(value.substr(7), 120)
                        }
                    },
                    {
                        key: 'uploadDate',
                        label: this.$t('forms.created'),
                        sortable: true,
                        formatter: value => {
                            return moment(value).format('DD.MM.YYYY, HH:mm');
                        }
                    }
                ]
            }
        },
        mounted() {
            app = this
            axios.get(Const.API_HOST + '/admin/productCategories')
                .then(function (response) {
                    return response.data
                }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.categories = body
            })
            if (this.$attrs.mode === Const.MODE_EDIT) {
                axios.get(Const.API_HOST + '/admin/productItem/get', {
                    params: {
                        'id': this.$route.params.id
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.item = body
                    this.itemName = JSON.parse(JSON.stringify(this.item.name))

                    if (this.item.features == null) {
                        this.newFeatures = []
                    } else {
                        this.newFeatures = this.item.features
                    }

                    if (this.item.additionalServices == null) {
                        this.newAS = []
                    } else {
                        this.item.additionalServices.forEach(function (as) {
                            if (as.netPrice === undefined || as.netPrice === null) {
                                as.netPrice = 0
                            }
                            as.netPrice = as.netPrice.toFixed(2)
                        })
                        this.newAS = this.item.additionalServices
                    }
                })
            } else {
                this.item = {
                    name: '',
                    nameEn: '',
                    itemOrder: '',
                    title: '',
                    titleEn: '',
                    description: '',
                    descriptionEn: '',
                    category: {},
                    image: {},
                    image360: {},
                    thumbnail: {},
                    datasheet: {},
                    features: [],
                    additionalServices: [],
                    summary: '',
                    summaryEn: '',
                    url: '',
                    canBuy: false
                }
            }
        },
        methods: {
            trimEllip(s, length) {
                return s.length > length ? s.substring(0, length) + "..." : s;
            },
            resetInputValue() {
                this.newTag = ''
            },
            cancel() {
                this.$router.push('/products')
            },
            saveItem() {
                var app = this
                axios.post(Const.API_HOST + '/admin/productItem/save', {
                    productItem: {
                        id: this.item.id,
                        name: this.item.name,
                        nameEn: this.item.nameEn,
                        itemOrder: this.item.itemOrder,
                        title: this.item.title,
                        titleEn: this.item.titleEn,
                        description: this.item.description,
                        descriptionEn: this.item.descriptionEn,
                        category: this.item.category,
                        image: this.item.image,
                        image360: this.item.image360,
                        thumbnail: this.item.thumbnail,
                        datasheet: this.item.datasheet,
                        features: this.newFeatures,
                        summary: this.item.summary,
                        summaryEn: this.item.summaryEn,
                        url: this.item.url,
                        canBuy: this.item.canBuy,
                        additionalServices: this.newAS
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/products')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            deleteItem() {
                var app = this
                axios.delete(Const.API_HOST + '/admin/productItem/delete', {
                    data: {
                        id: this.item.id
                    }
                }).then(function (response) {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    if (response.status === 200) {
                        app.$router.push('/products')
                    }
                }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                })
            },
            openImagePicker(context) {
                this.imagePickerContext = context
                this.$bvModal.show('image-picker')
                axios.get(Const.API_HOST + '/admin/images')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.images = body
                })
            },
            openFilePicker(context) {
                this.filePickerContext = context
                this.$bvModal.show('file-picker')
                axios.get(Const.API_HOST + '/admin/files')
                    .then(function (response) {
                        return response.data
                    }).catch(function (error) {
                    // eslint-disable-next-line no-console
                    console.log(error)
                }).then(body => {
                    this.files = body
                })
            },
            pasteImage(id) {
                var selected = this.images.find(obj => {
                    return obj.id === id
                })

                // eslint-disable-next-line no-console
                console.log(selected.filename)
                let html = '<p class="par-with-images"><img src="' + selected.filename + '" alt="' + selected.alt + '" width="' + selected.width + 'px" height="' + selected.height + 'px"></p>';

                if (this.imagePickerContext === 'summary-input') {
                    this.$refs.formEditor.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'summaryEn-input') {
                    this.$refs.formEditorEn.editor.execCommand('mceInsertContent', false, html);
                } else if (this.imagePickerContext === 'thumbnail-input') {
                    this.item.thumbnail = JSON.parse(JSON.stringify(selected))
                } else if (this.imagePickerContext === 'image-input') {
                    this.item.image = JSON.parse(JSON.stringify(selected))
                } else if (this.imagePickerContext === 'image360-input') {
                    this.item.image360 = JSON.parse(JSON.stringify(selected))
                }

                this.$bvModal.hide('image-picker')
                this.images = []
            },
            pasteFile(item) {
                // eslint-disable-next-line no-console
                console.log(item)
                if (this.filePickerContext === 'datasheet-input') {
                    this.item.datasheet = JSON.parse(JSON.stringify(item))
                }

                this.$bvModal.hide('file-picker')
                this.files = []
            },
            addAdditionalServiceRow() {
                let index = this.newAS.length + 1;
                this.newAS.push({
                      name: '',
                      nameEn: '',
                      serviceType: 'RECURRING_FEE',
                      serviceOrder: index,
                      netPrice: 0,
                      selectedDefault: false
                })
            },
            deleteAdditionalServiceRow(index) {
                this.newAS.splice(index,1)
                for (let i = 0; i < this.newAS.length; i++) {
                    this.newAS[i].serviceOrder = i+1;
                }
            },
            addFeatureRow() {
                this.newFeatures.push({
                    name: '',
                    nameEn: ''
                })
            },
            deleteFeatureRow(index) {
                this.newFeatures.splice(index,1)
            },
            nameInputValidation(index) {
                return this.newAS[index].name && this.newAS[index].name.length > 5
            },
            nameEnInputValidation(index) {
                return this.newAS[index].nameEn !== undefined && this.newAS[index].nameEn.length > 5
            },
            featureNameInputValidation(index) {
                return this.newFeatures[index].name && this.newFeatures[index].name.length > 5
            },
            featureNameEnInputValidation(index) {
                return this.newFeatures[index].nameEn !== undefined && this.newFeatures[index].nameEn.length > 5
            },
            netPriceValidation(index) {
                return this.newAS[index].netPrice >= 0
            },
            serviceOrderValidation(index) {
                return this.newAS[index].serviceOrder > 0
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

    .browser-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    /deep/ .tox-editor-container div:first-child {
        top: 58px !important;
    }
</style>
