<template lang="html">
    <div id="app">
        <div v-if="!isLogged" class="container-scroller">
            <login/>
        </div>
        <div v-else class="container-scroller">
            <app-header/>
            <div class="container-fluid page-body-wrapper">
                <app-sidebar/>
                <div class="main-panel">
                    <div class="content-wrapper">
                        <router-view></router-view>
                    </div>
                    <app-footer/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import { mapGetters } from 'vuex'
    import AppHeader from '../src/components/partials/AppHeader'
    import AppSidebar from '../src/components/partials/AppSidebar'
    import AppFooter from '../src/components/partials/AppFooter'
    import Login from "./views/pages/admin/Login";

    export default {
        name: 'main',
        components: {
            Login,
            AppHeader,
            AppSidebar,
            AppFooter
        },
        computed: mapGetters([
            'isLogged'
        ]),
    }
</script>

<style>
    @import "../node_modules/mdi/css/materialdesignicons.min.css";
</style>

<style lang="scss">
    @import "./assets/scss/style";

    #app, .container-scroller {
        min-height: 100vh;
    }

    .min-45px-w {
        min-width: 45px;
    }
</style>
