<template>
    <section class="product-item">
        <div class="row">
            <div class="col grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="forms-sample">
                            <div class="row">
                                <div class="col-lg-6">
                                    <h3>{{ $t('forms.clientDetails') }}</h3>
                                    <table class="table">
                                        <tbody>
                                        <tr>
                                            <th scope="row">{{ $t('forms.firstname') }}</th>
                                            <td>{{item.name}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.surname') }}</th>
                                            <td>{{item.surname}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.fiscalNumber') }}</th>
                                            <td>{{item.fiscalNumber}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.companyName') }}</th>
                                            <td>{{item.companyName}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.contactPhone') }}</th>
                                            <td>{{item.phone}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.email') }}</th>
                                            <td>{{item.email}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.postal') }}</th>
                                            <td>{{item.postal}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-lg-6">
                                    <h3>{{ $t('forms.orderDetails') }}</h3>
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="font-weight-bold">{{ $t('forms.orderId') }}</th>
                                            <th scope="col">{{item.id}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th scope="row">{{ $t('forms.created') }}</th>
                                            <td>{{createdDateFormat()}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.extraInfo') }}</th>
                                            <td>{{item.extraInfo}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.deliveryAddress') }}</th>
                                            <td>{{item.deliveryAddress}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.deliveryDate') }}</th>
                                            <td>{{prettyDate(item.deliveryDate)}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.endDate') }}</th>
                                            <td>{{prettyDate(item.endDate)}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.rentalPeriod') }}</th>
                                            <td>{{datePeriod}} {{ $t('forms.days') }}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.deliveryContactPhone') }}</th>
                                            <td>{{item.contactPhone}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.deliveryInfo') }}</th>
                                            <td>{{item.deliveryInfo}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.regulationAccepted') }}</th>
                                            <td>{{item.statConsent ? $t('forms.yes') : $t('forms.no')}}</td>
                                        </tr>
                                        <tr>
                                          <th scope="row">{{ $t('forms.owuAccepted') }}</th>
                                          <td>{{item.owuConsent ? $t('forms.yes') : $t('forms.no')}}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{{ $t('forms.rodoAccepted') }}</th>
                                            <td>{{item.adConsent ? $t('forms.yes') : $t('forms.no')}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col">
                                    <h3>{{ $t('forms.basketPositions') }}</h3>
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{{ $t('forms.productName') }}</th>
                                            <th scope="col">{{ $t('forms.service') }}</th>
                                            <th scope="col">{{ $t('forms.quantity') }}</th>
                                            <th scope="col">{{ $t('forms.periods') }}</th>
                                            <th scope="col">{{ $t('forms.unitPrice') }}</th>
                                            <th scope="col">{{ $t('forms.netPrice') }}</th>
                                            <th scope="col">{{ $t('forms.grossPrice') }}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(position, index) in item.basket.products">
                                            <tr :key="position.name + position.service">
                                                <th scope="row">{{index + 1}}</th>
                                                <td>{{ position.name }}</td>
                                                <td>{{ position.service }}</td>
                                                <td>{{ position.quantity }}</td>
                                                <td>{{ item.basket.fullPeriods.toFixed(2) }}</td>
                                                <td>{{ prettyPrice(position.unitNetPrice) }}</td>
                                                <td>{{ prettyPrice(position.netPrice) }}</td>
                                                <td>{{ prettyPrice(position.grossPrice) }}</td>
                                            </tr>
                                            <tr v-for="(additionalService, id) in position.additionalServices" :key="id">
                                                <th scope="row"></th>
                                                <td>{{ additionalService.name }}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{{ prettyPrice(additionalService.unitNetPrice) }}</td>
                                                <td>{{ prettyPrice(additionalService.netPrice) }}</td>
                                                <td>{{ prettyPrice(additionalService.grossPrice) }}</td>
                                            </tr>
                                        </template>
                                        <tr v-if="item.basket.totalNetPrice">
                                            <th scope="row" colspan="6" class="text-right">{{ $t('forms.orderValue') }}</th>
                                            <td class="table-info">{{ prettyPrice(item.basket.totalNetPrice) }}</td>
                                            <td class="table-success">{{ prettyPrice(item.basket.totalGrossPrice) }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <b-button class="mt-5" variant="light" v-on:click="back">{{ $t('forms.back') }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import {Const} from '../../../main'
    import moment from 'moment'

    export default {
        name: 'OrderRequest',
        data() {
            return {
                item: {},
                datePeriod: 0
            }
        },
        mounted() {
            axios.get(Const.API_HOST + '/admin/order/get', {
                params: {
                    'id': this.$route.params.id
                }
            }).then(function (response) {
                return response.data
            }).catch(function (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }).then(body => {
                this.item = body
                const calcStartDate = this.parseDate(this.item.deliveryDate)
                const calcEndDate = this.parseDate(this.item.endDate)
                this.datePeriod = this.calculatePeriod(calcStartDate, calcEndDate)

            })
        },
        methods: {
            back() {
                if (this.item.paymentOrderId)
                    this.$router.push('/paid-order-requests')
                else
                    this.$router.push('/order-requests')
            },
            getAdditionalServiceType(quantity, vas) {
                if (vas === null) {
                    return ""
                } else if (vas.serviceType === "ONE_TIME_FEE") {
                    return quantity
                } else if (vas.serviceType === "RECURRING_FEE") {
                    return quantity.toString() + this.$t('forms.recurringFeeQty')
                } else {
                    return ""
                }
            },
            createdDateFormat() {
                return moment(this.item.createdDate).format('DD.MM.YYYY, HH:mm');
            },
            parseDate(date) {
                if (!date)
                    return

                var dateStr = date.toString().replace(/\D/g,'')
                // eslint-disable-next-line no-console
                console.log(dateStr)
                if (date.toString().startsWith("20")) {
                    return new Date(
                        Number(dateStr.substr(0, 4)),
                        Number(dateStr.substr(4, 2)) - 1,
                        Number(dateStr.substr(6, 2)),
                    )
                } else {
                    return new Date(
                        Number(dateStr.substr(4, 4)),
                        Number(dateStr.substr(2, 2)) - 1,
                        Number(dateStr.substr(0, 2)),
                    )
                }
            },
            calculatePeriod(startDate, endDate) {
                if (!startDate || !endDate)
                    return

                return Math.round((endDate.getTime() - startDate.getTime()) / 86400000)
            },
            prettyDate(date) {
                if (!date)
                    return
                var dateStr = this.parseDate(date)
                return moment(dateStr).locale('pl').format('DD.MM.YYYY (dddd)');
            },
            prettyPrice(price) {
                if (price === undefined || price === null) {
                    return ""
                } else {
                    return price.toFixed(2)
                }
            }
        }
    }
</script>

<style scoped>
    .clickable /deep/ tbody td {
        cursor: pointer;
    }

    .browser-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    .table td {
        white-space: normal;
    }
</style>
